

<template>
  <div class="main-conent main-conent-minheight" v-loading="loading">
    <el-card shadow="never" class="border-none">
      <div slot="header">
        <el-row>
          <el-col :span="18">
            <el-input placeholder="请输入用户昵称" v-model="searchForm.title" clearable />
          </el-col>
          <el-col :span="6">
            <el-button type="primary" @click="dataBindingInit()">搜索</el-button>
          </el-col>
        </el-row>
      </div>
      <el-table :data="tableData" stripe fit>
        <el-table-column prop="id" label="昵称" width="80">
          <template slot-scope="scope">
            <div class="Middle">
              <el-avatar size="small" :src="scope.row.avatar"></el-avatar>
              <label>{{scope.row.nickName}}</label>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="id" label="编号" />
        <el-table-column prop="point" label="功力值" />
        <el-table-column prop="sexDesc" label="性别" />
        <el-table-column prop="mobile" label="手机" />
        <el-table-column prop="job" label="职业" />
        <el-table-column prop="email" label="email" />
        <el-table-column prop="city" label="城市" />
        <el-table-column prop="address" label="地址" />
        <el-table-column prop="createDateTime" label="创建日期" />
        <el-table-column label="操作" min-width="100">
          <template slot-scope="scope">
            <el-button v-if="!scope.row.isSuper" @click="openPointDialog(scope.row)" type="text">功力值操作</el-button>
            <el-button v-if="!scope.row.isSuper" @click="toPointRecordList(scope.row)" type="text">功力值明细</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="margin-t16 text-right">
        <el-pagination background layout="prev, pager,next" :total="totalCount" :current-page="searchForm.page"
          :page-size="searchForm.pageSize" @current-change="handleCurrentChange">
        </el-pagination>
      </div>

    </el-card>
    <el-dialog title="功力值编辑" :visible.sync="dialogFormVisible" v-loading="loading" :close-on-click-modal="false">
      <el-form :model="pointFormData">
        <el-form-item label="正负积分">
          <el-input-number v-model="pointFormData.point" autocomplete="off" :controls="false"></el-input-number>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="pointFormData.remark" autocomplete="off" type="textarea" rows="6"
            placeholder="请输入备注信息，用户可见" :autosize="true"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="handlePointModel()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getList, updatePoint } from '@/service/appuser.js';

export default {
  data() {
    return {
      dialogFormVisible: false,
      loading: false,
      searchOpen: false,
      totalCount: 0,
      pointFormData: {
        appUserId: 0,
        point: 0,
        remark: ''
      },
      searchForm: {
        page: 1,
        pageSize: 20,
        title: ''
      },
      tableData: []
    };
  },
  created() {
    this.dataBinding();
  },
  methods: {
    //加载页面数据
    dataBinding() {
      this.loading = true;
      getList(this.searchForm).then(res => {
        console.log(res);
        this.totalCount = res.total;
        this.tableData = res.list;
        this.loading = false;
      });
    },
    //清空页码
    dataBindingInit() {
      this.searchForm.page = 1;
      this.dataBinding();
    },
    //打开操作用户积分面板
    openPointDialog(row) {
      this.pointFormData.appUserId = row.id;
      this.dialogFormVisible = true;
    },
    //跳转个人积分列表
    toPointRecordList(row) {
      // this.$router.push({ name: 'appuser-point-list' });
      this.$router.push({ name: 'appuser-point-list', query: { appUserId: row.id } });
    },
    //提交用户积分编辑
    handlePointModel() {
      if (this.pointFormData.appUserId <= 0) {
        this.$message('用户丢失，请刷新页面');
        return;
      }
      else if (this.pointFormData.point == 0) {
        this.$message('请输入积分');
        return;
      } else if (!this.pointFormData.remark) {
        this.$message('请输入备注信息');
        return;
      }
      updatePoint(this.pointFormData).then(res => {
        if (res != true)
          this.$refs.message('编辑错误，请刷新页面后重试！');
        //关闭弹窗，刷新数据
        this.pointFormData.appUserId = 0;
        this.dialogFormVisible = false;
        this.dataBinding();
      });
    },
    //页码切换
    handleCurrentChange(val) {
      if (this.searchForm.page != val) {
        this.searchForm.page = val;
        this.dataBinding();
      }
    }

  }
};
</script>
<style scoped>
.Middle {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
</style>